<template>
  <div class="immediates">
    <!-- 立即分期 -->
    <div class="firstBox">
      <!-- 合作医院 -->
      <div class="hospital">
        <p class="top">
          <img src="./img/yiyuan.png" alt="" />
          <span>合作医院</span>
        </p>
        <p class="bottom">
          <span>{{ intentionList.partnerName }}</span>
          <!-- <van-icon class="arrow" name="arrow" /> -->
        </p>
      </div>
      <div class="applyitem">
        <p>申请项目</p>
        <p
          class="itemName"
          v-for="(item, index) in intentionList.projectName"
          :key="index"
        >
          {{ index + 1 }}.{{ item }}
        </p>
      </div>
      <div class="itemapply">
        <p>项目总金额(元)</p>
        <p class="redMoney">
          {{ $filters.formatMoney(intentionList.projectAmount, 2) }}
        </p>
      </div>
    </div>
    <div class="twoBox firstBox">
      <!-- 分期金额 -->
      <div class="divisionMoney">
        <van-field
          class="money"
          v-model="divivalue"
          @blur="contrastMoney"
          type="text"
          label="分期金额(元)"
          placeholder="请输入100的整数倍"
          input-align="right"
        />
        <img @click="delInput" src="./img/del.png" alt="" />
      </div>
      <!-- 选择期数 -->
      <div class="monthDigit">
        <van-field
          v-model="numberStage"
          is-link
          readonly
          name="picker"
          label="分期期数"
          placeholder=""
          @click="showNumber = true"
        />
        <van-popup v-model:show="showNumber" position="bottom">
          <van-picker
            value-key="value"
            :columns="numberData"
            @confirm="numberCheck"
            @cancel="showNumber = false"
          />
        </van-popup>
      </div>
      <!-- 手术时间 -->
      <div class="monthDigit">
        <van-field
          v-model="operatDate"
          is-link
          readonly
          name="picker"
          label="手术时间"
          placeholder=""
          @click="showCard = true"
        />
        <van-popup v-model:show="showCard" position="bottom">
          <van-datetime-picker
            type="date"
            title="选择年月日"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="showCard = false"
            @confirm="confirmPicker"
          />
        </van-popup>
      </div>
      <!-- 还款账户 -->
      <div class="backAccount monthDigit">
        <van-cell
          title="还款账户"
          is-link
          :value="backAccount"
          @click="showAccount"
        />
      </div>
    </div>
    <!-- 底部 -->
    <!-- 相关协议 -->
    <div class="loan">
      <van-checkbox
        class="base-footer-text"
        icon-size="18px"
        checked-color="#8486F8"
        v-model="checked"
      ></van-checkbox>
      <p>
        我已阅读并同意
        <span
          v-for="item in protocollist"
          :key="item"
          @click="seeProtocol(item.contractUrl)"
          >《{{ item.contractName }}》</span
        >
      </p>
    </div>
    <div class="bottomBox">
      <div class="bottomLeft">
        <p class="bottom_top">
          <span class="every_title">每期应还：</span>
          <span class="money_red" v-if="everyBacked">￥{{ everyBacked }}</span>
          <span class="money_red" v-else>￥0<i>起</i></span>
        </p>
        <p class="bottom_bottom" @click="showBacklist">
          <span>查看还款计划</span>
          <van-icon class="toparrow" name="arrow-up" />
        </p>
      </div>
      <div :class="checked ? 'bottomRight' : 'bottomRight1'" @click="submit">
        提交
      </div>
    </div>

    <!-- 点击查看还款计划 -->
    <div class="backplan backList">
      <van-popup v-model:show="showBackamt" position="bottom">
        <div class="backAmt">
          <p class="backTitle">
            <span>选择计划仅供参考，以实际放款时间及计划为准</span>
            <img @click="closeBacklist" src="./img/close.png" alt="" />
          </p>
          <div class="backList_item" v-for="item in backAmtLists" :key="item">
            <p>
              <span>第{{ item.planIndex }}期</span>
              <span class="gray">({{ item.planDate }})</span>
            </p>
            <p>
              <span>￥{{ item.planAmt }}</span>
            </p>
          </div>
        </div>
      </van-popup>
    </div>
    <!-- 点击查看银行卡 -->
    <div class="backplan account">
      <van-popup v-model:show="showAccountlist" position="bottom">
        <div class="backAmt account">
          <p class="backTitle">
            <span>选择还款的银行</span>
            <img @click="closeAccount" src="./img/close.png" alt="" />
          </p>
          <van-radio-group v-model="checkedBank" checked-color="#7370F6">
            <van-radio
              :name="index"
              v-for="(item, index) in bankList"
              :key="index"
            >
              <div class="backList_item">
                <img :src="item.logo" alt="" />
                <span>{{ item.openBankName }}({{ item.bankAccount4 }})</span>
              </div>
            </van-radio>
          </van-radio-group>

          <div class="backList_item add" @click="toaddAccount">
            <img src="./img/addAccount.png" alt="" />
            <span>添加新的银行卡</span>
          </div>
          <div class="btnensure" @click="ensureInfo">确认</div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import APP from "@/utils/App";
import { routerTag } from "../../utils/index";
import API from "../../server/api";

export default {
  name: "ImmediateStaging",
  setup() {
    // const {
    //   appContext { config: { globalProperties } } } = getCurrentInstance();
    const internalInstance = getCurrentInstance();
    internalInstance.appContext.config.globalProperties;
    console.log(internalInstance.appContext.config.globalProperties, "999");
    const state = reactive({
      minDate: new Date(),
      maxDate: new Date(2050, 10, 1),
      divivalue: "",
      divivalue1: "",
      numberStage: "", // 分期期数
      showNumber: false, // 展示期数的下拉框
      numberData: [12, 24], // 期数列表
      showItem: false, // 展示项目列表
      itemData: ["jgg", "fff"], // 项目列表
      showPop: false, // 提示框
      showBackamt: false, // 还款计划
      itemValue: "",
      itemlist: [],
      showCard: false,
      checked: false,
      checkedBank: false,
      itemMoney: "", // 项目总金额
      operatDate: "", // 手术时间
      showDate: false,
      backAccount: "", // 银行卡
      showAccountlist: false,
      fileNumber: "", // 资料上传的个数
      creditNo: "",
      userId: "",
      everyBacked: "",
      manualAmount: "",
      bankList: [],
      backAmtLists: [],
      bankAccount: "",
      protocollist: [],
      intentionList: {
        parterName: "",
        projectName: [],
        projectAmount: "",
      },
    });
    onMounted(() => {
      //  APP.PAGE_WILL_LOAD(function(){
      //   window.location.reload();
      // })
      APP.SET_TITLE("立即分期");
      state.creditNo = localStorage.getItem("creditNo");
      state.userId = localStorage.getItem("userId");
      getDataList();
      bankList();
      getprotocolList();
      APP.PAGE_WILL_LOAD(function () {
        getDataList();
        bankList();
        getprotocolList();
      });
    });
    // 获取协议列表
    const getprotocolList = async () => {
      const protocolList = await API.getProtocols({
        userId: state.userId,
        type: "2",
        creditNo:state.creditNo
      });
      if (protocolList.code == 200) {
        state.protocollist = protocolList.result;
      }
      // state.protocollist = []
      // for (var key in protocolList) {
      //   state.protocollist.push(protocolList[key])
      // }
      console.log(state.protocollist, "协议列表");
    };
    // 获取意向单信息
    const getDataList = async () => {
      const dataList = await API.intention({});
      console.log(dataList, "00000000");
      if (dataList.code == 200) {
        const {
          partnerName,
          projectName,
          projectAmount,
          periods,
          manualAmount,
          loanIntentionNum,
          manualNum
        } = dataList;
        state.intentionList.partnerName = partnerName;
        state.intentionList.projectName = projectName;
        state.intentionList.projectAmount = projectAmount;
        state.numberData = periods;
        state.manualAmount = manualAmount;
        state.divivalue = manualAmount;
        state.numberStage = manualNum?manualNum:loanIntentionNum;
        backamtPlan();
      }

      // state.divivalue= internalInstance.appContext.config.globalProperties.$filters.formatMoney(
      //     manualAmount,
      //     2
      //   );
      // console.log(dataList, "结果");
    };
    // 判断输入的金额
    const contrastMoney = () => {
      if (state.divivalue % 100 != 0) {
        Toast.fail("请输入100的整数倍!");
        state.divivalue = "";
        return false;
      }
      if (state.divivalue <= 0) {
        Toast.fail("请输入有效金额!");
        state.divivalue = "";
        return false;
      }
      if (state.divivalue > Number(state.manualAmount)) {
        Toast.fail("借款金额超限");
        state.divivalue = "";
        return false;
      }
      // state.divivalue1 = state.divivalue
      // state.divivalue =
      //   internalInstance.appContext.config.globalProperties.$filters.formatMoney(
      //     state.divivalue,
      //     2
      //   )
      state.numberStage = "";
    };

    // 点击确定显示期数
    const numberCheck = (value) => {
      state.numberStage = value;
      state.showNumber = false;
      if (!state.divivalue) {
        Toast.fail("请输入分期金额");
        return;
      }
      if (!state.numberStage) {
        Toast.fail("请选择分期期数");
        return;
      }
      backamtPlan();
    };
    // 点击删除项目
    const delItem = (n) => {
      // console.log(111)
      state.itemlist.splice(n, 1);
    };
    // 点击清空输入框
    const delInput = () => {
      state.divivalue = "";
    };
    // 查看还款计划
    const backamtPlan = async () => {
      const backAmtList = await API.getLoanNum({
        creditNo: state.creditNo,
        loanIntentionAmount: state.divivalue,
        loanIntentionNum: state.numberStage,
      });
      if (backAmtList.code == 200) {
        state.backAmtLists = backAmtList.result;
      }
      // state.backAmtLists = []
      // for (var key in backAmtList) {
      //   state.backAmtLists.push(backAmtList[key])
      // }
      state.everyBacked = state.backAmtLists[0].planAmt;
      // console.log(state.backAmtLists, "借款金额");
    };
    // 点击提交
    const submit = async () => {
      if (!state.checked) {
        Toast("请先阅读相关协议");
        return false;
      }
      if (!state.divivalue) {
        Toast.fail("请输入分期金额");
        return;
      }
      if (!state.numberStage) {
        Toast.fail("请选择分期期数");
        return;
      }
      if (!state.operatDate) {
        Toast.fail("请选择手术时间");
        return;
      }
      if (!state.bankAccount) {
        Toast.fail("请选择还款账户");
        return;
      }
      // console.log(state.divivalue,state.divivalue1);
      const loanInfoData = await API.loanInfo({
        bankAccount: state.bankAccount,
        creditNo: state.creditNo,
        userId: state.userId,
        loanAmount: state.divivalue,
        loanPeriod: state.numberStage,
        projectTime: state.operatDate,
      });
      if (loanInfoData) {
        APP.JUMP_TO(`${routerTag}infoDetail`);
      }
      console.log(loanInfoData, "返回数据");
      // state.showPop = true;
    };
    // 查看协议
    const seeProtocol = (url) => {
      APP.JUMP_TO(url);
    };
    // 选择时间
    const confirmPicker = (val) => {
      const year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      state.operatDate = `${year}-${month}-${day}`;
      state.showCard = false;
    };

    // 点击查看还款计划
    const showBacklist = () => {
      if (!state.divivalue) {
        Toast.fail("请输入分期金额");
        return;
      }
      if (!state.numberStage) {
        Toast.fail("请选择分期期数");
        return;
      }
      state.showBackamt = true;
    };
    // 点击❌关闭还款计划
    const closeBacklist = () => {
      state.showBackamt = false;
    };
    // 查看银行卡列表
    const bankList = async () => {
      const bankListData = await API.seecardList({
        userId: state.userId,
        creditNo: state.creditNo,
      });
      if (bankListData.code == 200) {
        state.bankList = bankListData.result;
      }
      // console.log(state.bankList, 'Hhh')
    };
    // 点击选择银行卡确认
    const ensureInfo = () => {
      console.log(state.checkedBank, "银行卡");
      state.bankAccount = state.bankList[state.checkedBank].bankAccount;
      state.backAccount =
        state.bankList[state.checkedBank].openBankName +
        "(" +
        state.bankList[state.checkedBank].bankAccount4 +
        ")";
      console.log(state.bankAccount, "09876");
      state.showAccountlist = false;
    };
    // 点击打开银行卡列表
    const showAccount = () => {
      state.showAccountlist = true;
    };
    // 关闭银行卡列表
    const closeAccount = () => {
      state.showAccountlist = false;
    };

    // 去添加银行卡
    const toaddAccount = () => {
      APP.JUMP_TO(`${routerTag}addAccount`);
    };

    return {
      ...toRefs(state),
      numberCheck,
      delInput,
      delItem,
      submit,
      // mysee,
      // mysure,
      showBacklist,
      closeBacklist,
      showAccount,
      closeAccount,
      toaddAccount,
      confirmPicker,
      ensureInfo,
      seeProtocol,
      contrastMoney,
    };
  },
};
</script>
<style lang="less" src="./index.less" scoped></style>
<style>
.immediates .divisionMoney .van-field__control {
  font-size: 36px;
  font-family: DIN-Medium, DIN;
  font-weight: 500;
  color: #fe4040;
}
.immediates .van-cell::after {
  border: none;
}
.immediates .divisionMoney .van-field__label {
  width: 30%;
  color: #1e1e1e;
  font-weight: 500;
}
.immediates .van-cell__value {
  color: #bbbbbb;
}
.immediates .van-field {
  width: 100%;
  /* height: 100%; */
}
.immediates .backplan .van-popup {
  background: none;
}
.backList .van-popup {
  background: none;
  height: 800px;
}
.immediates .backAmt .van-popup {
  position: initial;
  /* overflow-y: scroll; */
}

.immediates .account .van-radio {
  /* width: 100%; */
  border-bottom: 1px solid #f0f0f0;
}
.immediates .monthDigit .van-field__label {
  color: #1e1e1e;
  font-weight: 500;
}
.immediates .monthDigit .van-field__control {
  color: #1e1e1e;
  text-align: right;
}
.immediates .backAccount .van-cell__value {
  color: #1e1e1e;
  text-align: right;
}
.immediates .backAccount .van-cell__title {
  color: #1e1e1e;
  font-weight: 500;
}
/* .immediates .account .van-radio__icon {
  position: absolute;
  right: 30px;
} */
</style>
